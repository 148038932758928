import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import axios from 'axios';
import './BDEDashboard.css';  // Include the updated CSS file

const BDEDashboard = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [editingJobId, setEditingJobId] = useState(null);  
  const [formData, setFormData] = useState({});  // Store form data
  const [skills] = useState([
    'HTML', 'CSS', 'JavaScript', 'Python', 'Java', 'Node.js', 'React.js', 'Angular', 'Vue.js',
    'Machine Learning', 'Django', 'Spring Boot', 'C++', 'C#', 'Ruby', 'PHP',
    'Flask', 'Bootstrap', 'MySQL', 'TypeScript', 'Go', 'Rust', 'Kotlin',
    'SQL', 'Shell Scripting', 'VB.NET', 'MATLAB', 'R', 'AWS', 'DevOps',
    'Hibernate', 'Spring', 'JSP', 'Servlets'
  ]);

  const [branches] = useState([
    'CSE', 'ISE', 'IT', 'ECE', 'EEE', 'CIVIL', 'MECH', 'AIML', 'AIDS', 
    'CSD', 'MBA', 'MTECH CSE', 'IoT', 'BBA', 'BCA', 'BSC', 'MCA', 'MSC'
  ]);
  

  

  const [currentSkill, setCurrentSkill] = useState('');
  const [customSkill, setCustomSkill] = useState('');
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [technologiesError, setTechnologiesError] = useState('');
  const [years] = useState(Array.from({ length: 10 }, (_, index) => 2015 + index)); // Generating years from 2015 to 2024

  const [currentYear, setCurrentYear] = useState('');
  const [selectedYears, setSelectedYears] = useState([]);
  const [yearsError, setYearsError] = useState('');
  const [currentBranch,setCurrentBranch] = useState('');
  const [customBranch,setCustomBranch] = useState('')
  const [selectedBranches,setSelectedBranches] = useState([]);
 const [branchError,setBranchError] = useState('')

 const addBranch = () => {
  if (!currentBranch && !customBranch) {
    setBranchError('Please select a branch.');
    return;
  }

  const newBranch = currentBranch === 'Other' ? customBranch : currentBranch;

  if (selectedBranches.includes(newBranch)) {
    setBranchError('This branch is already added.');
    return;
  }

  setSelectedBranches([...selectedBranches, newBranch]);
  setCurrentBranch('');
  setBranchError('');
  setCustomBranch('')

};

const removeBranch = (branch) => {
  setSelectedBranches(selectedBranches.filter((b) => b !== branch));
};

  const addYear = () => {
    if (!currentYear) {
      setYearsError('Please select a year.');
      return;
    }

    if (selectedYears.includes(currentYear)) {
      setYearsError('This year is already added.');
      return;
    }

    setSelectedYears([...selectedYears, currentYear]);
    setCurrentYear('');
    setYearsError('');
  };

  const removeYear = (year) => {
    setSelectedYears(selectedYears.filter((s) => s !== year));
  };


  const addSkill = () => {
    if (!currentSkill && !customSkill) {
      setTechnologiesError('Please select or enter a skill.');
      return;
    }

    const newSkill = currentSkill === 'Other' ? customSkill : currentSkill;

    if (selectedSkills.includes(newSkill)) {
      setTechnologiesError('This skill is already added.');
      return;
    }

    setSelectedSkills([...selectedSkills, newSkill]);
    setCurrentSkill('');
    setCustomSkill('');
    setTechnologiesError('');
  };

  // Remove a skill from the selected skills list
  const removeSkill = (skill) => {
    setSelectedSkills(selectedSkills.filter((s) => s !== skill));
  };


  // Fetch job details 
  const fetchJobs = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/listopenings`);
      setJobs(response.data.jobs);
      setLoading(false);
      console.log(response.data.jobs)
    } catch (error) {
      setError('Failed to fetch job details');
      setLoading(false);
    }
  };

  // Handle form data change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle edit button click
  const handleEditClick = (job) => {
    setEditingJobId(job.job_id);  
    setFormData({
      jobRole: job.jobRole,
      salary: job.salary,
      graduates: job.graduates.join(', '),  
      educationQualification: job.educationQualification,
      department: job.department.join(', '),  
      percentage: job.percentage,
      technologies: job.technologies.join(', '),  // Convert array to string
      jobLocation: job.jobLocation,
      specialNote: job.specialNote,
      bond: job.bond
    });
    setSelectedSkills(job.technologies || [])
    setSelectedYears(job.graduates || [])
    setSelectedBranches(job.department || []);
  }; 

  // Handle form submission (update job)
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedJob = {
      job_id: editingJobId,
      jobRole: formData.jobRole,
      salary: formData.salary,
      graduates: selectedYears,
      educationQualification: formData.educationQualification,
      department:selectedBranches,
      percentage: formData.percentage,
      jobSkills: selectedSkills,
      jobLocation: formData.jobLocation,
      specialNote: formData.specialNote,
      bond: formData.bond
    };

    try {
     await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/editjob`, updatedJob);      
     console.log(updatedJob)
      fetchJobs();

      setEditingJobId(null);
      setFormData({});
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to update job details');
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  return (
    <div className="dashboard-container">
      <h2 className="dashboard-title" >BDE Dashboard</h2>
      {loading && <p className="loading-message">Loading jobs...</p>}
      {error && <p className="error-message">{error}</p>}
      
      {editingJobId ? (
        <div className="job-edit-form">
          <h3 className="form-title">Edit Job</h3>
          <form className="job-form" onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label className="form-label">Job Role:</label>
              <input
                className="form-input"
                type="text"
                name="jobRole"
                value={formData.jobRole || ''}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label className="form-label">Salary:</label>
              <input
                className="form-input"
                type="text"
                name="salary"
                value={formData.salary || ''}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label className="form-label">Graduate:</label>
              <div className="years-container">
                <select
                  id="years"
                  name="years"
                  value={currentYear}
                  onChange={(e) => setCurrentYear(e.target.value)}
                  className="form-input select"
                >
                  <option value="">Select a year</option>
                  {years.map((year, index) => (
                    <option key={index} value={year} className='skill-option'>{year}</option>
                  ))}
                </select>
                {yearsError && <p className="error-message">{yearsError}</p>}
                <button type="button" className="add-year-btn" onClick={addYear}>
                  Add Year
                </button>
              </div>
              <div className="selected-years">
                {selectedYears.map((year, index) => (
                  <p key={index} className="selected-year">
                    {year}
                    <button type="button" className="remove-year-btn" onClick={() => removeYear(year)}>X</button>
                  </p>
                ))}
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">Education Qualification:</label>
              <input
                className="form-input"
                type="text"
                name="educationQualification"
                value={formData.educationQualification || ''}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
      <label htmlFor="branch"  className="form-label">Branch/Stream</label>
      <div className="skills-container">
        <select
          id="branch"
          name="branch"
          value={currentBranch}
          onChange={(e) => setCurrentBranch(e.target.value)}
          className="form-input select"
        >
          <option value="">Select a Branch</option>
          {branches.map((branch, index) => (
            <option className='skill-option' key={index} value={branch}>{branch}</option>
          ))}
          <option value="Other" className='other'>Other</option>
        </select>
        {currentBranch === 'Other' && (
          <input
            type="text"
            placeholder="Enter custom Branch"
            value={customBranch}
            onChange={(e) => setCustomBranch(e.target.value)}
            className="form-input select"
          />
        )}
        {branchError && <p className="error-message">{branchError}</p>}
        <button type="button" className="add-skill-btn" onClick={addBranch}>
          Add Branch
        </button>
      </div>
      <div className="selected-skills">
        {selectedBranches.map((skill, index) => (
          <p key={index} className="selected-skill">
            {skill}
            <button type="button" className="remove-skill-btn" onClick={() => removeBranch(skill)}>X</button>
          </p>
        ))}
      </div>
    </div>
            <div className="form-group">
              <label className="form-label">Percentage Criteria:</label>
              <input
                className="form-input"
                type="number"
                name="percentage"
                value={formData.percentage || ''}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
      <label htmlFor="skills"  className="form-label">Skills</label>
      <div className="skills-container">
        <select
          id="skills"
          name="skills"
          value={currentSkill}
          onChange={(e) => setCurrentSkill(e.target.value)}
          className="form-input select"
        >
          <option value="">Select a skill</option>
          {skills.map((skill, index) => (
            <option className='skill-option' key={index} value={skill}>{skill}</option>
          ))}
          <option value="Other" className='other'>Other</option>
        </select>
        {currentSkill === 'Other' && (
          <input
            type="text"
            placeholder="Enter custom skill"
            value={customSkill}
            onChange={(e) => setCustomSkill(e.target.value)}
            className="form-input select"
          />
        )}
        {technologiesError && <p className="error-message">{technologiesError}</p>}
        <button type="button" className="add-skill-btn" onClick={addSkill}>
          Add Skill
        </button>
      </div>
      <div className="selected-skills">
        {selectedSkills.map((skill, index) => (
          <p key={index} className="selected-skill">
            {skill}
            <button type="button" className="remove-skill-btn" onClick={() => removeSkill(skill)}>X</button>
          </p>
        ))}
      </div>
    </div>
            <div className="form-group">
              <label className="form-label">Job Location:</label>
              <input
                className="form-input"
                type="text"
                name="jobLocation"
                value={formData.jobLocation || ''}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label className="form-label">Special Note:</label>
              <textarea
                className="form-textarea"
                name="specialNote"
                value={formData.specialNote || ''}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Bond (in years):</label>
              <input
                className="form-input"
                type="number"
                name="bond"
                value={formData.bond || ''}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-buttons">
              <button type="submit" className="submit-btn">Update Job</button>
              <button type="button" onClick={() => setEditingJobId(null)} className="cancel-btn">Cancel</button>
            </div>
          </form>
        </div>
      ) : (
        <>
        {jobs.length > 0 && (
           <div className="job-container">
           {jobs.map(job => (
               <div key={job.job_id} className="job-card">
                   <h3>{job.companyName}</h3>
                   <p><span className="job-key">Job Role:</span> {job.jobRole}</p>
                   <p><span className="job-key">Salary:</span> {job.salary}</p>
                   <p><span className="job-key">Graduate: </span> {job.graduates.join(', ')}</p>
                   <p><span className="job-key">Education Qualification:</span>  {job.educationQualification}</p>
                   <p><span className="job-key">Branch/Stream:</span>  {job.department.join(', ')}</p>
                   <p><span className="job-key">Percentage Criteria:</span>  {job.percentage}%</p>
                   <p><span className="job-key">Eligible Technologies:</span>  {job.technologies.join(', ')}</p>
                   <p><span className="job-key">Job Location:</span>  {job.jobLocation }</p>
                   <p><span className="job-key">Special Note:</span>  {job.specialNote }</p>
                   <p style={{ marginBottom: "5%" }}><span className="job-key">Bond:</span> {job.bond} years</p>
                   <div className='btns'>
                       <Link to={`/bdestudentsappliedjoblist/${job.job_id}`} className='applied-students-list'>Applied Students</Link>
                       <button className="edit-btn" onClick={() => handleEditClick(job)}>Edit</button>
                   </div>
               </div>
           ))}
       </div>
      )}
        </>
      )}
    </div>
  );
};

export default BDEDashboard;
